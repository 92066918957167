import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getChannelConnect, getChannelFiles, getWixConnect } from '../../Api/UploadChannel';
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from '../../Store/loader/loaderReducer';
import { toast } from 'react-toastify';
import styles from './Integration.module.css';
import axios from 'axios';
import IntegrationModal from '../../Component/Modal/IntegrationModal/IntegrationModal';
import { integration_action } from '../../Store/Integrations/IntegrationAction';
import { orgInfoAction } from '../../Store/OrgInfo/orgInfo_action';

const initialIntegration = [
    { name: 'Wix', channel: 1, describe: 'Upload documents directly from WIX to here.', img: 'Integrations/wix.svg' },
    { name: 'Zoho CRM', channel: 3, describe: 'Upload documents directly from Zoho CRM to here.', img: 'Integrations/zoho.svg' },
    { name: 'WordPress', channel: 7, describe: 'Publish blogs on your WordPress site.', img: 'Integrations/wordpress.svg' },
    { name: 'Custom Site', channel: 2, describe: 'Publish blogs on your Custom Server site.', img: 'Integrations/custom_site.png' },
    // { name: 'Twitter', channel: 6, describe: 'Share your blog posts on your Twitter page.', img: 'Integrations/twitter.svg' },
    // { name: 'Instagram', channel: 5, describe: 'Share your blog posts on your Instagram page.', img: 'Integrations/instagram.svg' },
    // { name: 'Facebook', channel: 4, describe: 'Share your blog posts on your Facebook page.', img: 'Integrations/facebook.svg' }
    { name: 'Wix OAuth', channel: 9, describe: 'Upload documents directly from WIX OAuth to here.', img: 'Integrations/wix.svg' },
    { name: 'WordPress OAuth', channel: 10, describe: 'Upload documents directly from WordPress OAuth to here.', img: 'Integrations/wordpress.svg' },
];

const initialState = { app_name: '', base_domain: '', blog_path: '', target_location: '', username: '', password: '' };
const wixInitialState = { api_key: '', member_id: '', site_id: '' };

const settings = [1, 2, 7];

const keyErrorText = {
    api_key: "Api Key",
    member_id: "Member ID",
    site_id: "Site ID",
    app_name: 'Application Name',
    base_domain: 'Base Domain',
    blog_path: 'Blog Path',
    target_location: 'Server Location Path',
    username: 'Username',
    password: 'Password'
};

const Integration = () => {
    const [modal, setModal] = useState(false);
    const [apiKey, setApiKey] = useState(wixInitialState);
    const [formDetails, setFormDetails] = useState(initialState);
    const [connected, setConnected] = useState(false);
    const [channel, setChannel] = useState([]);
    const [channelDetails, setChannelDetails] = useState([]);
    const [channelId, setChannelId] = useState({});
    const [update, setUpdate] = useState(false);
    const [disButton, setDisButton] = useState(null);
    const [move, setMove] = useState(null);
    const [integration, setIntegration] = useState(initialIntegration);
    const [is_closed, setIs_closed] = useState(true);

    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;
    const { integration_information, is_connected } = useSelector((state) => state.integrationDetails.value);

    const dispatch = useDispatch();

    useEffect(() => {
        if ((is_connected && integration_information?.length) || is_closed) handleCheckConnected();
    }, [integration_information, is_connected]);

    const handleCheckConnected = () => {
        let arr = [];
        let obj = {};
        let channel_id = {};
        integration_information?.map((list, i) => {
            if (arr?.indexOf(list.Channel_Type) === -1) {
                obj = { ...obj, [list.Channel_Type]: true };
                channel_id = { ...channel_id, [list.Channel_Type]: list.Channel_Id ?? list.Account_Id };
                arr.push(list.Channel_Type);
            }
        });
        setChannel(arr);
        setConnected(obj);
        setChannelId(channel_id);
        setChannelDetails(integration_information);
    };

    useEffect(() => {
        const show_features = org_info?.show_features?.length ? JSON.parse(org_info?.show_features)[0]?.features : [];
        if (!show_features?.WIX_OAUTH && show_features?.hasOwnProperty('WIX_OAUTH')) {
            setIntegration(current =>
                current.filter(item => {
                    return item.name !== 'Wix OAuth';
                }),
            );
        };

        if (!show_features?.WORDPRESS_OAUTH && show_features?.hasOwnProperty('WORDPRESS_OAUTH')) {
            setIntegration(current =>
                current.filter(item => {
                    return item.name !== 'WordPress OAuth';
                }),
            );
        };

        if (!show_features?.SOCIAL_FACEBOOK && show_features?.hasOwnProperty('SOCIAL_FACEBOOK')) {
            setIntegration(current =>
                current.filter(item => {
                    return item.name !== 'Facebook';
                }),
            );
        };
    }, []);

    const handleConnect = (item, re_connect) => {
        let options = { org_id: org_id, channel_type: item.channel };
        if (item.channel === 3 || item?.channel === 9 || item?.channel === 10) {
            setChannel(pre => [...pre, item?.channel]);
            getChannelConnect(options, (res) => {
                dispatch(toggleLoader(false));
                let childWin = window.open(res.data.auth_url, "_blank");
                let userAgent = navigator.userAgent;
                let winClosed = setInterval(function () {
                    if (childWin.closed) {
                        clearInterval(winClosed);
                        handleChannelType(options, item?.channel, re_connect);
                        setIs_closed(false);
                    }
                    else if (childWin.length === 0 && !(userAgent.indexOf("Firefox") !== -1)) {
                        if (childWin.location.href.includes('callback')) {
                            childWin.close()
                        }
                    } else if (childWin.length === 0 && userAgent.indexOf("Firefox") !== -1) {
                    }
                }, 250);
            }, (err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        } else {
            switch (item.channel) {
                case 1:
                    setApiKey(wixInitialState);
                    setModal('wix');
                    break;
                case 2:
                    setFormDetails(initialState);
                    setModal('custom_site');
                    break;
                case 7:
                    setFormDetails(initialState);
                    setModal('word_press');
                    break;
                default:
                    break;
            }
        }
    };

    const handleChannelType = (options, type, re_connect) => {
        dispatch(toggleLoader(true));
        getChannelFiles(options, (res) => {
            let data = res.data;
            if (data.client_access_token) {
                let list = { Channel_Type: type, Channel_Id: data.channel_id };
                let disconnect = channelDetails?.find((s, i) => s.Channel_Id === data.channel_id);
                if (disconnect) {
                    const _channelDetails = channelDetails?.map((item, i) => {
                        if (item?.Channel_Id === data.channel_id) {
                            return { ...item, Status: 1 }
                        }
                        return item;
                    });
                    setChannelDetails(_channelDetails);
                } else {
                    setChannelDetails([...channelDetails, list]);
                };

                if (re_connect === 'reconnect') {
                    const reConnect = channelDetails?.filter((list, i) => list?.Channel_Type !== type);
                    setChannelDetails([...reConnect, list]);
                    dispatch(integration_action({ integration_information: [...reConnect, list] }));
                } else {
                    if (channelDetails?.length) {
                        let obj = [...channelDetails];
                        obj.push(list);
                        dispatch(integration_action({ integration_information: obj }));
                    } else {
                        dispatch(integration_action({ integration_information: [list] }));
                    }
                }
                setChannelId({ ...channelId, [type]: data?.channel_id ?? data?.account_id });
                setConnected({ ...connected, [type]: data.client_access_token });
            }
            dispatch(toggleLoader(false));
        }, (err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
        })
    };

    const handleOpenModal = (list) => {
        switch (list) {
            case 1:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_WIX, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            api_key: data?.Access_Token,
                            member_id: data?.Account_Id,
                            site_id: data?.Site_Id,
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setApiKey(obj);
                        setModal('wix');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            case 2:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_CUSTOM_SERVER, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            app_name: data?.App_Name,
                            base_domain: data?.Base_Domain,
                            blog_path: data?.Blog_Path,
                            target_location: data?.Target_Location,
                            username: data?.Username,
                            password: data?.Password
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setFormDetails(obj);
                        setModal('custom_site');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            case 7:
                dispatch(toggleLoader(true));
                axios.get(process.env.REACT_APP_WORD_PRESS, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
                    .then((res) => {
                        let data = res?.data?.data;
                        let obj = {
                            app_name: data?.App_Name,
                            base_domain: data?.Base_Domain,
                            blog_path: data?.Blog_Path,
                            target_location: '',
                            username: data?.Username,
                            password: data?.Password
                        };
                        setChannelId((pre) => ({ ...pre, [list]: data.Id }));
                        setFormDetails(obj);
                        setModal('word_press');
                        setUpdate(true);
                        dispatch(toggleLoader(false));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        toast.error(err.message);
                    });
                break;
            default:
                break;
        }
    };

    const handleWixSubmit = () => {
        let params = {
            api_key: apiKey?.api_key,
            member_id: apiKey?.member_id,
            site_id: apiKey?.site_id,
        };

        const emptyFields = Object.keys(params).filter(
            (field) => typeof params[field] !== "string" || params[field].trim() === ""
        );

        params['org_id'] = org_id;
        params['id'] = channelId[1];
        if (!emptyFields?.length) {
            setIs_closed(false);
            dispatch(toggleLoader(true));
            if (!update) {
                axios.post(process.env.REACT_APP_WIX, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        let data = res?.data?.data;
                        setChannel(pre => [...pre, data.Channel_Type]);
                        setConnected((prevState) => ({ ...prevState, [data.Channel_Type]: true }));
                        setChannelId((pre) => ({ ...pre, [data.Channel_Type]: data.Channel_Id }));
                        setModal(false);
                        setApiKey({});
                        setChannelDetails(pre => [...pre, data]);
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                        if (channelDetails?.length) {
                            let obj = [...channelDetails];
                            obj.push(data);
                            dispatch(integration_action({ integration_information: obj }));
                        } else {
                            let list = { Channel_Type: data.Channel_Type, Channel_Id: data.Channel_Id };
                            dispatch(integration_action({ integration_information: [list] }));
                        }

                        let newObj = { Channel_Type: data.Channel_Type, App_Name: data?.App_Name }
                        let blog_platform = { ...org_info };
                        blog_platform['blog platform'] = [...org_info['blog platform'], newObj];
                        dispatch(orgInfoAction(blog_platform));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            } else {
                axios.put(process.env.REACT_APP_WIX, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        setModal(false);
                        setUpdate(false);
                        setApiKey({});
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'channel_already_connected' || error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            }
        } else {
            toast.error(`${keyErrorText[emptyFields[0]]} is required.`);
        }
    };

    const handleFormsSubmit = () => {
        let params = {
            app_name: formDetails?.app_name,
            base_domain: formDetails?.base_domain,
            blog_path: formDetails?.blog_path,
            username: formDetails?.username,
            password: formDetails?.password,
        };

        if (modal === 'custom_site') params['target_location'] = formDetails?.target_location;

        const emptyFields = Object.keys(params).filter(
            (field) => typeof params[field] !== "string" || params[field].trim() === ""
        );

        params['org_id'] = org_id;
        if (!emptyFields?.length) {
            setIs_closed(false);
            dispatch(toggleLoader(true));
            if (!update) {
                axios.post(formDetails?.target_location ? process.env.REACT_APP_CUSTOM_SERVER : process.env.REACT_APP_WORD_PRESS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        let data = res.data.data;
                        setChannel(pre => [...pre, data.Channel_Type]);
                        setConnected((prevState) => ({ ...prevState, [data.Channel_Type]: true }));
                        setChannelId((pre) => ({ ...pre, [data.Channel_Type]: data.Channel_Id }));
                        setModal(false);
                        setFormDetails({});
                        setChannelDetails(pre => [...pre, data]);
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                        if (channelDetails?.length) {
                            let obj = [...channelDetails];
                            obj.push(data);
                            dispatch(integration_action({ integration_information: obj }));
                        } else {
                            let list = { Channel_Type: data.Channel_Type, Channel_Id: data.Channel_Id };
                            dispatch(integration_action({ integration_information: [list] }));
                        }

                        let newObj = { Channel_Type: data.Channel_Type }
                        if (data.Channel_Type === 2) newObj['App_Name'] = formDetails?.app_name;
                        let blog_platform = { ...org_info };
                        blog_platform['blog platform'] = [...org_info['blog platform'], newObj];
                        dispatch(orgInfoAction(blog_platform));
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            } else {
                let type = modal === 'custom_site' ? 2 : 7;
                params['id'] = channelId[type];
                axios.put(formDetails?.target_location ? process.env.REACT_APP_CUSTOM_SERVER : process.env.REACT_APP_WORD_PRESS, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        setModal(false);
                        setUpdate(false);
                        setFormDetails({});
                        dispatch(toggleLoader(false));
                        toast.success('Success');
                    })
                    .catch((err) => {
                        dispatch(toggleLoader(false));
                        let error = err?.response?.data?.error;
                        if (error?.message === 'channel_already_connected' || error?.message === 'invalid_data') {
                            toast.error(err.response.data.error.details);
                        } else {
                            toast.error(err.message);
                        }
                    });
            }
        } else {
            toast.error(`${keyErrorText[emptyFields[0]]} is required.`);
        }
    };

    const handleDisconnect = (type) => {
        dispatch(toggleLoader(true));
        let data = { org_id: org_id, channel_type: type, channel_id: channelId[type] };
        axios.delete(process.env.REACT_APP_DISCONNECT, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
            .then((res) => {
                let obj = channel?.indexOf(type);
                let data = channel?.filter((k, i) => i !== obj);
                setChannel(data);
                let details = channelDetails?.filter((s) => s?.Channel_Type !== type);
                dispatch(integration_action({ integration_information: details ?? null }));
                setConnected({ ...connected, [type]: false });
                let remove_channel = { ...channelId };
                delete remove_channel[type];
                setChannelId(remove_channel);
                let blog_platform = { ...org_info };
                blog_platform["blog platform"] = blog_platform["blog platform"].filter(
                    platform => platform.Channel_Type !== type
                );
                dispatch(orgInfoAction(blog_platform));
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
    };

    let expired = {};
    return (
        <Box className={styles.integration_container}>
            {integration?.map((item, index) => {
                let channel_connect = connected[item?.channel] && channel?.find((channel) => channel === item?.channel);
                channelDetails?.map((s, i) => channel_connect && s.Status === 2 ? expired[s.Channel_Type] = s.Channel_Type : null);
                return (
                    <Box className={styles.integration_body} key={index} onMouseOver={() => setDisButton(index)} onMouseOut={() => [setDisButton(null), setMove(null)]}>
                        <Box sx={{ '&:hover': { opacity: channel_connect && disButton === index ? '0.4' : '1.0' }, padding: '20px !important', position: 'relative', opacity: move === index ? '0.4' : '1.0' }}>
                            <Box className={styles.integration_box}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + item?.img}
                                    className={styles.dropbox_img}
                                    alt='dropbox'
                                />
                                <Box sx={{ display: 'flex' }}>
                                    <Button variant="outlined" sx={{ opacity: channel_connect && disButton === index && '0 !important' }} className={channel_connect ? styles.box_connected : styles.box_connect} style={{ cursor: channel_connect ? 'not-allowed' : 'pointer' }} onClick={() => !channel_connect && handleConnect(item)}>{channel_connect ? 'Connected' : 'Connect'}</Button>
                                </Box>
                            </Box>
                            <Box className={styles.integration_text}>
                                <Typography className={styles.top_text}>
                                    {item?.name}
                                </Typography>
                                <Typography className={styles.bottom_text}>
                                    {item?.describe}
                                </Typography>
                            </Box>
                        </Box>
                        {channel_connect && disButton === index &&
                            <Box className={styles.disconnect} onMouseOver={() => setMove(index)} sx={{ opacity: disButton === index && '1 !important', zIndex: '999 !important' }}>
                                {settings.includes(item?.channel) && channel_connect &&
                                    <Box className={styles.settings_img}>
                                        <img className={styles.nav_name} alt={item.name} src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Settings.svg'} onClick={() => handleOpenModal(item?.channel)} />
                                    </Box>}
                                {channel_connect && <Button variant="outlined" className={expired[item?.channel] ? styles.reconnect_button : styles.disconnect_button} onClick={() => expired[item?.channel] ? handleConnect(item, 'reconnect') : handleDisconnect(item?.channel)}>{expired[item?.channel] ? 'Reconnect' : 'Disconnect'}</Button>}
                            </Box>}
                    </Box>)
            })}
            <IntegrationModal
                modal={modal}
                setModal={() => [setModal(false), update && setUpdate(false)]}
                apiKey={apiKey}
                formDetails={formDetails}
                handleChange={(value, key) => setApiKey({ ...apiKey, [key]: value })}
                handleFormDetails={(value, key) => setFormDetails({ ...formDetails, [key]: value })}
                handleWixSubmit={handleWixSubmit}
                update={update}
                handleFormsSubmit={handleFormsSubmit}
            />
        </Box>
    )
};

export default Integration;